import { Box } from '@chakra-ui/react';
import CreateAccordion from '../Minors/AccordionInstance';
import MarketPlaceFaq from '../questions/marketPlaceFaq';

function Marketplace() {
  return (
    <>
      {MarketPlaceFaq?.map(({ title, answer }, index) => {
        return (
          <>
            <CreateAccordion key={index} header={title} text={answer} />
          </>
        );
      })}
    </>
  );
}

export default Marketplace;
