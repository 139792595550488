import { Box } from '@chakra-ui/react';
import CreateAccordion from '../Minors/AccordionInstance';
import MicrolendingQuestions from '../questions/microlending';

function MicrolendingFaq() {
  return (
    <>
      {MicrolendingQuestions?.map(({ title, answer }) => {
        return (
          <>
            <Box>
              <CreateAccordion header={title} text={answer} />
            </Box>
          </>
        );
      })}
    </>
  );
}

export default MicrolendingFaq;
