const MarketPlaceFaq = [
    
    {
    title:'  How do I complete the setup of my profile? ',
    answer: [
      `Once you log on to the website www.gogeeper.com, you can sign in to your account using your Email or Phone Number and the registered password. You can decide to save your login details for ease of future access.`,
      `If you do not have an account, you can begin your registration process by creating a profile using your Email or Phone Number or signing up using your Google Account. Authentication will be required to complete your sign-in.`,
      `You can select to sign up as a Farmer, an agribusiness owner, an expert, or your best-suited category to have access to your account profile.

      `,

      `
      If you will need help setting up your store as a business owner, kindly watch this [video].`
    ]
    },

    {
      title: ' How do I buy on Go-Geeper?',
      answer: [
        `You can buy from the Go-Geeper marketplace as a signed user or a guest buyer.`,
        `Simply go to the Marketplace icon, select location and search for items from the product category.`,
        `You can add multiple items from the same store or other stores to your shopping basket`,
        `Proceed to checkout to confirm your order summary, shipping details and payment method.`,
        `Once your order has been confirmed, you will receive notifications until you have confirmed delivery of all your ordered items.`,
        `With multiple checkouts completed, confirmed, and delivered, you can easily find access to all your buying history from the store/marketplace.`,
        `We encourage you to leave feedback after purchase on products' quality and store service.`
      ]
    },
    {
      title: ' How do I sell on Go-Geeper?' ,
      answer: [
        `You can earn lots of money by listing available agro-commodities and related products to your Go-Geeper store.`,
        `To set up a store, you need to register your account as a farmer or a business owner. `,
        `Sellers are advised to be diligent in their delivery policies and order fulfilment responsibilities to avoid being flagged down and losing their accounts permanently.

        `
      ]
    },
    {
      title:'How will my ordered items be shipped to my location?',
      answer : [
        `At the point of order, you have two shipping options; Standard Shipping and Express Shipping.`,
        `Our Standard Shipping services ensure that you receive your ordered baskets within 7 - 10 days of request. While the Express shipping option will deliver items ordered within a fast-tracked 3 - 5 days request period - attracting an extra cost charge.

        `,
        `Upon payment and order confirmation, you can then track all your orders [here] to monitor delivery progress to your input location via our delivery partners.
        `,
        `N/B - Not all sellers allow Express Shipping requests because of the nature or complexity of the ordered item(s). [Track order]`
      ]
    },

    {
      title: 'What is the return policy?' ,
      answer: [
        `Due to the delicacies and complexities involved in the dispatch of certain items, returns are based on the seller's discretion.`,
        `Upon confirmation of eligibility to return, previously ordered items can be returned within not later than a 7-day period following these processes;

        Items placed in their original packaging, including any accessories, labels, and tags.`,
        `Ensure proper care in handling, otherwise any broken, punctured, or damaged item is invalid on return.`,
        `Items are sent to our pickup stations or picked up at request, within the stipulated time frame, with a return slip signed by our agents as your proof of return.`,
        `Once item(s) has been returned and received, we will inspect it and process for a refund or a replacement - usually within an earlier agreed delivery timeframe.

        `
      ]
    }
  
]

export default MarketPlaceFaq