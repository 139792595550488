import { Text } from "@chakra-ui/react"
const MicrolendingQuestions = [
    
  {
    title:'What is Microlending about?',
    answer: [
      'Our Microlending service seeks to provide smallholder farmers with financial support and literacy training to effectively manage and increase their incomes, create jobs, and support their families. '
    ]
  },
  {
    title:'How can I access these loans?',
    answer: [
      <Text>Join our <a href={'https://www.gogeeper.com/'} style={
        {
          fontWeight: '600',
          color: '#2aa505',
        }
      }>Waitlist</a> to gain first access to this service when we launch. </Text>
    ]
  },
  // {
  //   title:'Is the training Free?',
  //   answer: [
  //     ' '
  //   ]
  // }
  
  
]

export default MicrolendingQuestions