import { Box } from '@chakra-ui/react';
import CreateAccordion from '../Minors/AccordionInstance';
import CommodityFaq from '../questions/commodityLinkageFaq';

const CommodityLinkage = () => {
  return (
    <>
      {CommodityFaq?.map(({ title, answer }) => {
        return (
          <>
            <Box>
              <CreateAccordion header={title} text={answer} />
            </Box>
          </>
        );
      })}
    </>
  );
};

export default CommodityLinkage;
