
import ContainerLayout from '../../Layouts/ContainerLayout';
import { Avatar, Box, Flex, Text  } from '@chakra-ui/react';
const ProductUpdate =() => {
    return (
        <>

          <Box my={'3em'}>
            <ContainerLayout>

              <Text 
                fontWeight={'600'}
                fontSize={{'base':'20px' , 'md':'20px' , 'lg':'36px'}}>
                Frequently asked questions
              </Text>

              <Text>
                Everything you need to know about Go-Geeper
              </Text>
            
          </ContainerLayout>
          </Box>
        
        </>
    )
}


export default ProductUpdate;