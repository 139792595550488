import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Flex,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

import { useState } from 'react';

const CreateAccordion = ({ header, text }: any) => {
  // const [ data , setData ] = useState([text])

  //handle click effect
  const [open, setOpen] = useState<any>(true);

  const handleOpen = () => {
    setOpen(false);
  };

  return (
    <>
      <Accordion allowToggle>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton py={'1.5em'} _hover={{}}>
                  <Box as='span' flex='1' textAlign='left'>
                    {header}
                  </Box>
                  {isExpanded ? (
                    //   <MinusIcon fontSize='12px' />
                    <>
                      <svg
                        width='24'
                        height='26'
                        viewBox='0 0 24 26'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8 14H16M22 14C22 19.5228 17.5228 24 12 24C6.47715 24 2 19.5228 2 14C2 8.47715 6.47715 4 12 4C17.5228 4 22 8.47715 22 14Z'
                          stroke='#4CA30D'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    </>
                  ) : (
                    //   <AddIcon fontSize='12px' />
                    <>
                      <Box>
                        <svg
                          width='22'
                          height='22'
                          viewBox='0 0 22 22'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z'
                            stroke='#4CA30D'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          />
                        </svg>
                      </Box>
                    </>
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <UnorderedList>
                  {text?.map((items: any, index: number) => {
                    return (
                      <>
                        <ListItem key={index}>{items}</ListItem>
                      </>
                    );
                  })}
                </UnorderedList>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default CreateAccordion;
