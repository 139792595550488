const FaamasFaq = [
    
    {
      title:'How do I register my Farm on the platform?',
      answer: [
          `Go to www.gogeeper.com and select Create Account.`,
          `Select Account Type - I am a Farmer.`,
          `Sign up and Authenticate your Account to Get Started.`,
          `Set up your Account by providing your Personal Information, Farm Information and Farm Location.`,
          `Proceed to Account Verification by providing a Valid ID (This platform recognizes either of; NIMC Slip, National Identity Card, Voters Card and International Passport)`,
          `Add your preferred Payment Option and Save your details.

          `,
          `Join our Waitlist to be the first to know when we launch this feature.`
        ]
    },
    {
        title:'How can I get a job as an Agric Expert?',
        answer: [
            `Based on your specialised qualifications and proven experience, you can apply for jobs added by farmers to earn extra income.

            `,
            ` Sign in to gogeeper.com and set up your account as an Agric-Expert.`,
            ` Update your profile with accurate details and upload the required credentials.`,
            `A Go-Geeper administrator will contact you for Verification.

             Once your verification is complete, you can have full access to the Jobs available.`,
             ` Filter roles based on Location (Remote or On-site) and Salary (Min-Max.)`,
             `Click on the best-fit roles you want to submit your application.  `
            
        ]
    }
  ]
  
  export default FaamasFaq