const CommodityFaq = [
    
    {
      title:'What is the Commodity Linkage Feature about?',
      answer: [
        `The Commodity Linkage gives real-time information about the market prices, quantities, and varieties of agricultural commodities across various locations in Nigeria.`,
        
      ]
    },

       
    {
        title:'How can I use this feature?',
        answer: [
          `Click on Commodity Linkage to assess this feature.`,
          'Select items and filter to search based on availability, and varieties, and see current prices.',
          'You can view specific products, to observe their trends over the past days, weeks, and months.'
          
        ]
      }
    
  ]
  
  export default CommodityFaq