import { Box } from '@chakra-ui/react';

import CreateAccordion from '../Minors/AccordionInstance';
import FaamasFaq from '../questions/Faamas';

function FaaMs() {
  return (
    <>
      {FaamasFaq?.map(({ title, answer }) => {
        return (
          <>
            <Box>
              <CreateAccordion header={title} text={answer} />
            </Box>
          </>
        );
      })}
    </>
  );
}

export default FaaMs;
