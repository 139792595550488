import { Box } from '@chakra-ui/react'
import React from 'react'
import Faq from '../Micro/FAQ'
import CreateAccordion from '../Minors/AccordionInstance'
import FoodbankFaq from '../questions/FoodbankFaq'
import MicrolendingQuestions from '../questions/microlending'

function Foodbank() {
  return (
    <>

      {
         FoodbankFaq?.map ( ({title , answer}) => {
          return (
            <>

              <Box>
                <CreateAccordion header={title} text={answer} />
              </Box>

            </>
          )
        })
      }
    
    </>
  )
}

export default Foodbank