const FoodbankFaq = [
    
  {
    title:'How can I find a Foodbank close to me?',
    answer: [
        `After creating your account, the app takes you to a landing page of our featured services.
        `,
        `Click on Foodbank and input your location to find the nearest foodbank around you.`,
        `Our Foodbanks are categorised by proximity. You will see a collection of location pinpoints and addresses nearest to you and based on your eligibility.`
    ]
  } , {
    title:'How can I partner with Go-Geeper for its Foodbank services?',
    answer: [
        `
        We are open to partnership for our Foodbank program. Government and Non-Governmental Donor agencies, Corporate and Private Volunteer Organisations can support our food distribution initiative to provide the closest food emergency assistance service for those in need.`,
        `To reach us on this subject, kindly send a mail to partnerships@gogeeper.com.`
    ]
  },
  {
    title: 'How do I check my Eligibility to access the Foodbank services?',
    answer: [
        `Our Foodbank outlets are stocked up to provide emergency food assistance to those in need.`,
        `Join our Waitlist to be guided about the eligibility status when we launch. `
    ]
  }
  
]

export default FoodbankFaq