import {  Flex,Box, Heading, Text, InputGroup, InputRightElement, Button, Input } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import ContainerLayout from "../../Layouts/ContainerLayout";
import DefaultText from '../Micro/defaultText';
import ButtonInterface from "../Minors/ButtonInterface";


const Hero =() => {

    const navigate = useNavigate()

    return (

        <>

         <Box 
           bgImage={'https://res.cloudinary.com/dhkccnvyn/image/upload/v1674226790/Group_1_q7maw9.svg'}
            className={'hero-bg'} color={'#fff'} py={'9em'}  bgRepeat={'no-repeat'} 
            bgSize={'cover'} >

           <ContainerLayout>
          
            <Box textAlign={'center'} my={'3em'}>

              <DefaultText>What can we help you with ?</DefaultText>

              <Text>Find the answers to all of the most frequently asked questions</Text>
            </Box>

            <Box w={{"base":"100%" , "md":"100%" , "lg":"783px"}} mx={'auto'} my={'1em'}>
             
              
              {/* <Box bg={{'base':'none' , 'lg':'#fff'}}
                borderRadius={'8px'} p={['0','1em']} w={['' , '100%']} >
                    

                <Flex flexDir={{'base':'column', 'md':'column' , 'lg':'row'}}
                   gap={'1em'} alignItems={'center'}>
                     <FaSearch color="gray" size={'1.4em'}/>
                  <Input type={'Enter email address'}
                   border={['none' , '']} outline={'none'} focusBorderColor={'white'}
                   color={'gray'} px={0}
                   bg={'#fff'} placeholder={'search Item '} py={'1.5em'}/> 
                  
                  <ButtonInterface w={{'base':'100%' , 'md':'100%', 'lg':'30%'}}>Search</ButtonInterface>

                </Flex>

              </Box> */}


            </Box>

           

           </ContainerLayout>

         </Box>
        
        
        </>

    )
}


export default Hero;