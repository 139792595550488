
import { Text } from '@chakra-ui/react';

interface Props {
    children ?: any ,

}

const DefaultText =({children} : Props ) => {

    return (
        <>
        
          <Text fontSize={
            {'base':'24px' , 'md':'30px' , 'lg':'48px'}
            }

            fontWeight={'600'}
             >{children}</Text>
        
        </>
    )

}


export default DefaultText;